<template>
    <div class="center">
        <div class="banner">
            <a href="#" class="img">
                <div class="ncs-default-banner"></div>
            </a>
        </div>
        <div id="nav" class="ncs-nav">
            <ul>
                <li style="margin-right: 24px;" class="sad"><span>{{ shop.Name }},欢迎您</span></li>
                <li class="active">
                    <a href="#" onclick="return false"><span>店铺首页<i></i></span></a>
                </li>
                <li class="active">
                    <a @click.prevent="go" style="cursor: pointer;"><span>全部商品<i></i></span></a>

                </li>
            </ul>
        </div>
        <div class="bigBox flexspb fal">
            <div class="ncs-sidebar">

                <div class="ncs-sidebar-container ncs-class-bar">
                    <div class="title">
                        <h4>商品分类</h4>
                    </div>
                    <div class="content">
                        <!-- <p>
                            <span><a href="/home/Store/goods_list/store_id/3/key/on_time.html">新品</a></span>
                            <span><a href="/home/Store/goods_list/store_id/3/key/shop_price.html">价格</a></span>
                            <span><a href="/home/Store/goods_list/store_id/3/key/sales_sum.html">销量</a></span>
                            <span><a href="/home/Store/goods_list/store_id/3/key/comment_count.html">人气</a></span>
                        </p> -->
                        <div class="ncs-search">
                            <form id="" name="searchShop" method="get" action="/Home/Store/goods_list.html">
                                <input type="hidden" name="store_id" value="3" />
                                <input type="text" class="text w120" style="margin-right: 6px;" name="keyword" value=""
                                    placeholder="搜索店内商品" v-model="search">
                                <a  @click.prevent="gos" style="cursor: pointer;" class="ncs-btn">搜索</a>
                            </form>
                        </div>
                        <!-- <ul class="ncs-submenu">
                            <li><span class="ico-none"><em>-</em></span><a
                                    href="/home/Store/goods_list/store_id/3.html">全部商品</a></li>
                        </ul> -->
                    </div>
                </div>

                <div class="ncs-sidebar-container ncs-top-bar">
                    <div class="title">
                        <h4>商品排行</h4>
                    </div>
                    <div class="content">
                        <ul class="ncs-top-tab pngFix">
                            <li id="hot_sales_tab" class="current"><a
                                    href="/home/Store/goods_list/store_id/3/key/sales_sum.html">热销商品排行</a></li>
                        </ul>
                        <div id="hot_sales_list" class="ncs-top-panel">
                            <ol v-for="(item, index) in hot" :key="index">
                                <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
                                    <li>
                                        <dl>
                                            <dt>
                                                <a > {{ item.Name }}</a>
                                            </dt>
                                            <dd class="goods-pic"><a ><span
                                                        class="thumb size40"><i></i><img :src="getUrl(item.ImgUrl)"
                                                            style="width: 100%;height: 100%;"></span></a>
                                                <!-- <p><span class="thumb size100"><i></i><img
                                                            src="/public/upload/goods/thumb/12/goods_thumb_12_0_240_240.png?t=1670030760"
                                                            style="width: 100%;height: 100%;"
                                                            onload="javascript:DrawImage(this,100,100);"
                                                            title=" 麦迪恩 21.5英寸触摸屏手术室一体机 零噪声运行医用电脑 医用一体机 C21"><big></big><small></small></span>
                                                </p> -->
                                            </dd>
                                            <dd class="price pngFix ">￥{{ item.Price }}</dd>
                                            <dd class="selled pngFix">售出：<strong>{{ item.Sale }}</strong>笔</dd>
                                        </dl>
                                    </li>
                                </router-link>

                            </ol>
                        </div>

                        <p>
                            <a @click.prevent="go" style="cursor: pointer;">查看本店其他商品</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="litBox" style="flex:1;">
                <div class="ncs-main-container">
                    <div class="title"> <span>
                            <a @click.prevent="go" style="cursor: pointer;" class="more">更多</a>
                        </span>
                        <h4>推荐商品</h4>
                    </div>
                    <div class="content ncs-goods-list">
                        <ul>
                            <li v-for="(item, index) in tui" :key="index">
                                <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
                                    <dl>
                                        <dt><router-link
                                                :to="{ path: '/goods/details', query: { productID: item.Id } }">
                                                <a  class="goods-thumb"
                                                    target="_blank"><img :src="getUrl(item.ImgUrl)" /></a>
                                            </router-link>

                                        </dt>
                                        <dd class="goods-name"> {{item.Name}}</dd>
                                        <dd class="goods-info"><span class="price"><i>&yen;</i>￥{{ item.Price }}</span>
                                            <span class="goods-sold">已售：<strong>{{ item.Sale }}</strong> 件</span>
                                        </dd>
                                    </dl>
                                </router-link>
                            </li>

                        </ul>

                    </div>
                </div>
                <div class="ncs-main-container">
                    <div class="title"> <span>
                            <a @click.prevent="go" style="cursor: pointer;" class="more">更多</a>
                        </span>
                        <h4>新品</h4>
                    </div>
                    <div class="content ncs-goods-list">
                        <ul>
                            <li v-for="(item, index) in news" :key="index">
                                <router-link :to="{ path: '/goods/details', query: { productID: item.Id } }">
                                    <dl>
                                        <dt>
                                            <router-link
                                                :to="{ path: '/goods/details', query: { productID: item.Id } }">
                                                <a  class="goods-thumb"
                                                    target="_blank"><img :src="getUrl(item.ImgUrl)" /></a>
                                            </router-link>

                                        </dt>
                                        <dd class="goods-name"> {{item.Name}}</dd>
                                        <dd class="goods-info"><span class="price"><i>&yen;</i>￥{{ item.Price }}</span>
                                            <span class="goods-sold">已售：<strong>{{ item.Sale }}</strong> 件</span>
                                        </dd>
                                    </dl>
                                </router-link>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ids: 0,
            shop: [],
            hot: [],
            tui: [],
            news: [],
            search:""
        };
    },
    activated() {
        // 如果路由传递了categoryID，则显示对应的分类商品
        if (this.$route.query.id != undefined) {
            this.ids = this.$route.query.id;
            console.log(this.ids)
            this.getData()
        }
    },
    created() {

    },
    methods: {
        async getData() {
            var res = await this.postdata("/Shop/ShopDetail", {
                "queryId": this.ids
            });
            if (res.code == 200) {
                this.shop = res.data
            }
            res = await this.postdata("/Goods/GoodsList", {
                page: 1,
                limit: 4,
                IsHot: 1,
                "ShopId": this.ids, //店铺ID
            });
            this.hot = res.data.items
            res = await this.postdata("/Goods/GoodsList", {
                page: 1,
                limit: 4,
                IsTop: 1,
                "ShopId": this.ids, //店铺ID
            });
            this.tui = res.data.items
            res = await this.postdata("/Goods/GoodsList", {
                page: 1,
                limit: 4,
                IsNew: 1,
                "ShopId": this.ids, //店铺ID
            });
            this.news = res.data.items
        },
        go() {
            this.$router.push({
                path: "/shopGoodsAll",
                query: {
                    id: this.ids,
                },
            });
        },
        gos() {
            if(this.search == ''){
                return
            }
            this.$router.push({
                path: "/shopGoodsAll",
                query: {
                    id: this.ids,
                    search : this.search
                },
            });
        },
    }
};
</script>
<style scoped lang="less">
@import "../assets/css/shop.css";

.center {
    width: 1240px;
    margin: 0 auto;
}

.sad {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    color: #FFF;
    float: left;
    height: 20px;
    padding: 9px 15px;
    margin-left: 4px;
    overflow: hidden;
    cursor: pointer;
}
</style>
